import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  // templateUrl: './app.component.html',
  template: `
    <!-- routes will be rendered here -->
    <div class="avi-caja"></div>
    <router-outlet></router-outlet>
  `,
  styleUrls: [],
})
export class AppComponent {
  title = "bb-onboarding-seguros";
  
}
