import { Api } from "src/app/shared/enums/api.enum";

export const environment = {
  validarOtp: false,
  activarMail: false,
  production: true,
  offline: false,
  localhost:  "",
  company:"BOLIVARIANO",
  timeout:'20000',
  api:  "",
  codigoErrorEntrust:5203017,
  ValorOtp: 6,
  apiaws: Api.URI_SERVER, 
  uri_cognito: Api.URI_COGNITO,
  apiKey: Api.KEY,
  scope: Api.SCOPE,
  grantType: Api.GRANT_TYPE,
  Authorization: Api.AUTHORIZATION,
  ApiCliente:Api.API_CLIENTE,
  ApiSeguro:Api.API_SEGURO,
  ApiProducto:Api.API_PRODUCTO,
  ApiSeguridad:Api.API_SEGURIDAD,
  ApiCanal:Api.API_CANAL,
  productoTarjeta:"desgravamen",
  imagenesGea:true,
  imagenesEcua:true,
  medioEnvio:"M",
  medioEnvioM:"M",
  medioEnvioC:"",
  //para telefono C para correo M
  mediosPermitidos:"M",
  //mediosPermitidos === para telefono C para correo M y T todos
  productoAsegurado:"plan seguro",
  caracteristica:'fallecimiento natural',
  //AUTH
  ApiSeguridadF3:Api.API_SEGURIDAD,
  massiveAuthCanal:"CANAL@9",
  verifyQuestionCanal:"CANAL@1",
  medioCorreo: "Correo Electrónico",
  medioTelefono: "Celular",
  tamanioSoftoken:8,
  codigoIntentoFallido:5203000
};
