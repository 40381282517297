import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { ContainerLayoutComponent } from './layouts/container-layout/container-layout.component';
import { HeaderComponent } from './components/common/header/header.component'


@NgModule({
  declarations: [ContainerLayoutComponent, HeaderComponent],
  imports: [
    CommonModule, RouterModule
  ]
})
export class CoreModule { }
