import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { ContainerLayoutComponent } from "./core/layouts/container-layout/container-layout.component";



import { allRoutes } from './core/routes/all-routings'

const routes: Routes = [
  {
    path: "",
    component: ContainerLayoutComponent,
    children: allRoutes
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules/*, useHash : true */})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
