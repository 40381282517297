
/**
 * @export
 * @enum {API-DESARROLLO}
 */
export enum Api {
  // --- Desarrollo
  /*
  URI_SERVER = '',
  AUTHORIZATION = 'Basic NDFvdGVtNzFrZ2xhNzl2ZjlwcW5rdGlkM3Y6ZWRnaGMwMGUyNTc5MWhvZWVxNWpnNmFyZzRiajE5Ym00YjZnbmp2aGdxOHBtZWk2amht', //cogni
  GRANT_TYPE = 'client_credentials',
  SCOPE = 'bb-onbvsa-api-dev/bb-onbvsa-scope',
  KEY = 'oGZmLV3cZB6tOIvYMlqkc2OF83VpFyveawOfZki9',
  URI_COGNITO = 'https://bb-onbvsa-dev.auth.us-east-1.amazoncognito.com/oauth2/token',
  API_CLIENTE="https://touwf0yu01.execute-api.us-east-1.amazonaws.com/dev/",
  API_PRODUCTO="https://7qhq7nycz0.execute-api.us-east-1.amazonaws.com/dev/",
  API_SEGURIDAD="https://sfo6ucff8c.execute-api.us-east-1.amazonaws.com/dev/",
  API_SEGURO="https://et9zpqe9te.execute-api.us-east-1.amazonaws.com/dev/",  
  API_CANAL="https://touwf0yu01.execute-api.us-east-1.amazonaws.com/dev/obtener/identificacion",
  API_SEGURIDADF3="https://sfo6ucff8c.execute-api.us-east-1.amazonaws.com/dev/"
  */
  //--- Produccion
  URI_SERVER = '',  
  AUTHORIZATION = 'Basic NnEwdGZrbTYza2JlM3RtY2M4MzB1djI1ZzY6MXMxcmhxdmMwcjM0cDFiajZwcTQycHF0ajVxajA1cmJrMXFrcjhkNG5sNms1bGtpb3VrNA==', //cogni
  GRANT_TYPE = 'client_credentials', 
  SCOPE = 'bb-onbvsa-api-prd/bb-onbvsa-scope', 
  KEY = 'PsGbYKv1iI4wOXSASdqfdM43r7zm5mu4nmmvfp6h',
  URI_COGNITO = 'https://bb-onbvsa-prd.auth.us-east-1.amazoncognito.com/oauth2/token', 
  API_CLIENTE="https://j5wvr2u3r4.execute-api.us-east-1.amazonaws.com/prd/",
  API_PRODUCTO="https://gcmn2fadt0.execute-api.us-east-1.amazonaws.com/prd/",
  API_SEGURIDAD="https://g6n5yeaolk.execute-api.us-east-1.amazonaws.com/prd/",
  API_SEGURO="https://rcdhsridpc.execute-api.us-east-1.amazonaws.com/prd/", 
  API_CANAL="https://j5wvr2u3r4.execute-api.us-east-1.amazonaws.com/prd/obtener/identificacion",
  API_SEGURIDADF3="https://g6n5yeaolk.execute-api.us-east-1.amazonaws.com/prd/"

}






